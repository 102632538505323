// $footer-background: #1472B6;
// $blue: #1472B6;
// $footer-background: rgb(46,67, 151);
$footer-background: rgb(46,67, 151);
// $blue: rgb(46,67, 151);
$blue: rgb(46,67, 151);
$blue-hover: hsl(205, 80%, 25%);
$playfair: 'Playfair Display', serif;
$raleway: 'Raleway', sans-serif;
$montserrat: 'Montserrat', sans-serif;

// /* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {}
@media only screen and (max-width: 600px) {
    .footer {
        height: 40vh;
        width: 100vw;
        padding: 0;
        background-color: $footer-background;
        color: white;
        display: grid;
        grid-template-rows: 2fr 3fr 5fr 2.5fr;
        grid-template-columns: 15px 1fr 1fr 15px;
        font-family: $montserrat;
        z-index: 100;
    
        .footer-title {
            font-size: 1.2em;
            font-weight: 600;
            grid-row: 2 / 3;
            grid-column: 2 / 5;
            cursor: default;
            font-family: $raleway;
            background-color: $blue;
            height: fit-content;
            width: fit-content;
            color: white;
            padding: 6px 0px;
        }
    
        .footer-contact-us-details-container {
            grid-row: 5 / 5;
            grid-column: 2 / 4;
            cursor: default;
    
            .footer-contact-us {
                display: block;
                font-size: 1.1em;
                font-weight: 400;
                margin-bottom: 5vh;
            }
    
            .footer-contact-us-details {
                display: flex;
                margin-bottom: 2vh;
                font-size: .9em;
                font-weight: 300;
                align-items: center;
    
                .footer-details-icon {
                    height: 3vh;
                    width: 3vh;
                    margin-right: 20px;
                }
            }
        }
    
        .footer-copyright {
            grid-row: 5 / 5;
            grid-column: 2 / 5;
            font-size: .8em;
            font-weight: 300;
            margin-bottom: 15px;
        }
    
        .footer-social-media {
            grid-row: 4 / 5;
            grid-column: 2 / 4;
            height: auto;
            width: auto;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
    
            .social-media-icon {
                height: 3.5vh;
                width: 3.5vh;
                margin-right: 1.8vh;
                cursor: pointer;
                transition: .4s ease-out;
            }
    
            .social-media-icon:hover {
                transform: rotate(360deg);
                transition: .7s ease-in-out;
            }
        }
    
        .footer-submit-container {
            grid-row: 3 / 5;
            grid-column: 2 / 4;
    
            .footer-submit {
                // height: 100%;
                width: 100%;
                margin: 0;
                padding: 0;
    
                .footer-submit-title {
                    display: block;
                    font-size: 1.1em;
                    font-weight: 400;
                    margin-top: 2vh;
                    margin-bottom: 2vh;
                }
    
                .footer-form {
                    position: relative;
                    height: auto;
                    display: flex;
                    column-gap: 15px;
                    // flex-direction: column;
                }
    
                .footer-submit-input {
                    display: inline-block;
                    background-color: $footer-background;
                    border: 0px;
                    border-bottom: 1px solid white !important;
                    width: 40%;
                    color: white;
                    font-size: .9em;
                    position: relative;
                    padding-bottom: 5px;
                    top: 0;
                    height: 1.4em;
                    outline: none;
                }
    
                .footer-submit-input:focus ~ .input-label {
                    transform: translateY(-40px);
                    -webkit-transform: translateY(-40px);
                    transition: .3s;
                }
    
                .shrink {
                    transform: translateY(-40px);
                    -webkit-transform: translateY(-40px);
                }
    
                .input-label {
                    color: white;
                    font-size: .9em;
                    font-style: italic;
                    font-weight: 200;
                    position: absolute;
                    left: 0;
                    top: 16px;
                    pointer-events: none;
                    transition: .3s;
                }
    
                .footer-submit-btn {
                    height: 10%;
                    // width: 20%;
                    margin: 0 0 0 0px;
                    font-weight: 600;
                    font-size: .9em;
                    color: $footer-background;
                    background-color: white;
                    padding: 12px 20px;
                    border-radius: 50px;
                    transition: .35s;
                }
    
                .footer-submit-btn:hover {
                    background-color: #cacaca;
                    transition: .55s;
                }
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .footer {
        height: 40vh;
        width: 100vw;
        margin: 0;
        padding: 0;
        background-color: $footer-background;
        color: white;
        display: grid;
        grid-template-rows: 2fr 3fr 9fr 2.5fr;
        grid-template-columns: 10vw 3vw 1fr 1fr;
        font-family: $montserrat;
        z-index: 100;
    
        .footer-title {
            font-size: 1.2em;
            font-weight: 600;
            grid-row: 2 / 3;
            grid-column: 2 / 5;
            cursor: default;
            font-family: $raleway;
            background-color: $blue;
            height: fit-content;
            width: fit-content;
            color: white;
            padding: 6px 0px;
        }
    
        .footer-contact-us-details-container {
            grid-row: 5 / 5;
            grid-column: 2 / 4;
            cursor: default;
    
            .footer-contact-us {
                display: block;
                font-size: 1.1em;
                font-weight: 400;
                margin-bottom: 5vh;
            }
    
            .footer-contact-us-details {
                display: flex;
                margin-bottom: 2vh;
                font-size: .9em;
                font-weight: 300;
                align-items: center;
    
                .footer-details-icon {
                    height: 3vh;
                    width: 3vh;
                    margin-right: 20px;
                }
            }
        }
    
        .footer-copyright {
            grid-row: 4 / 5;
            grid-column: 4 / 5;
            font-size: .9em;
            font-weight: 300;
        }
    
        .footer-social-media {
            grid-row: 4 / 5;
            grid-column: 2 / 4;
            height: auto;
            width: auto;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
    
            .social-media-icon {
                height: 3.5vh;
                width: 3.5vh;
                margin-right: 1.8vh;
                cursor: pointer;
                transition: .4s ease-out;
            }
    
            .social-media-icon:hover {
                transform: rotate(360deg);
                transition: .7s ease-in-out;
            }
        }
    
        .footer-submit-container {
            grid-row: 3 / 5;
            grid-column: 2 / 5;
    
            .footer-submit {
                // height: 100%;
                width: 100%;
                margin: 0;
                padding: 0;
    
                .footer-submit-title {
                    display: block;
                    font-size: 1.1em;
                    font-weight: 400;
                    margin-top: 2vh;
                    margin-bottom: 2vh;
                }
    
                .footer-form {
                    position: relative;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                }
    
                .footer-submit-input {
                    display: inline-block;
                    background-color: $footer-background;
                    border: 0px;
                    border-bottom: 1px solid white !important;
                    width: 40%;
                    color: white;
                    font-size: .9em;
                    position: relative;
                    padding-bottom: 5px;
                    top: 0;
                    height: 1.4em;
                    outline: none;
                }
    
                .footer-submit-input:focus ~ .input-label {
                    transform: translateY(-40px);
                    -webkit-transform: translateY(-40px);
                    transition: .3s;
                }
    
                .shrink {
                    transform: translateY(-40px);
                    -webkit-transform: translateY(-40px);
                }
    
                .input-label {
                    color: white;
                    font-size: .9em;
                    font-style: italic;
                    font-weight: 200;
                    position: absolute;
                    left: 0;
                    top: 16px;
                    pointer-events: none;
                    transition: .3s;
                }
    
                .footer-submit-btn {
                    height: 10%;
                    width: 20%;
                    margin: 0 0 0 0px;
                    font-weight: 600;
                    font-size: .9em;
                    color: $footer-background;
                    background-color: white;
                    padding: 12px 20px;
                    border-radius: 50px;
                    transition: .35s;
                }
    
                .footer-submit-btn:hover {
                    background-color: #cacaca;
                    transition: .55s;
                }
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .footer {
        height: 55vh;
        width: 100vw;
        margin: 0;
        padding: 0;
        background-color: $footer-background;
        color: white;
        display: grid;
        grid-template-rows: 2fr 3fr 9fr 2.5fr;
        grid-template-columns: 10vw 1fr 1fr 10vw;
        font-family: $montserrat;
        z-index: 100;
    
        .footer-title {
            font-size: 1.2em;
            font-weight: 600;
            grid-row: 2 / 3;
            grid-column: 2 / 5;
            cursor: default;
            font-family: $raleway;
            background-color: $blue;
            height: fit-content;
            width: fit-content;
            color: white;
            padding: 6px 0px;
        }
    
        .footer-contact-us-details-container {
            grid-row: 3 / 4;
            grid-column: 2 / 3;
            cursor: default;
    
            .footer-contact-us {
                display: block;
                font-size: 1.1em;
                font-weight: 400;
                margin-bottom: 5vh;
            }
    
            .footer-contact-us-details {
                display: flex;
                margin-bottom: 2vh;
                font-size: .9em;
                font-weight: 300;
                align-items: center;
    
                .footer-details-icon {
                    height: 3vh;
                    width: 3vh;
                    margin-right: 20px;
                }
            }
        }
    
        .footer-copyright {
            grid-row: 4 / 5;
            grid-column: 3 / 5;
            font-size: .9em;
            font-weight: 300;
            margin-left: 15px;
        }
    
        .footer-social-media {
            grid-row: 4 / 5;
            grid-column: 2 / 4;
            height: auto;
            width: auto;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
    
            .social-media-icon {
                height: 3.5vh;
                width: 3.5vh;
                margin-right: 1.8vh;
                cursor: pointer;
                transition: .4s ease-out;
            }
    
            .social-media-icon:hover {
                transform: rotate(360deg);
                transition: .7s ease-in-out;
            }
        }
    
        .footer-submit-container {
            grid-row: 3 / 4;
            grid-column: 3 / 4;
            // margin-left: 15px;
    
            .footer-submit {
                height: 100%;
                width: 100%;
                margin: 0;
                padding: 0;
    
                .footer-submit-title {
                    display: block;
                    font-size: 1.1em;
                    font-weight: 400;
                    margin-bottom: 8vh;
                    margin-top: 0px !important;
                }
    
                .footer-form {
                    position: relative;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                }
    
                .footer-submit-input {
                    display: inline-block;
                    background-color: $footer-background;
                    border: 0px;
                    border-bottom: 1px solid white !important;
                    width: 40%;
                    color: white;
                    font-size: .9em;
                    position: relative;
                    padding-bottom: 5px;
                    top: 0;
                    height: 1.4em;
                    outline: none;
                }
    
                .footer-submit-input:focus ~ .input-label {
                    transform: translateY(-40px);
                    -webkit-transform: translateY(-40px);
                    transition: .3s;
                }
    
                .shrink {
                    transform: translateY(-40px);
                    -webkit-transform: translateY(-40px);
                }
    
                .input-label {
                    color: white;
                    font-size: .9em;
                    font-style: italic;
                    font-weight: 200;
                    position: absolute;
                    left: 0;
                    top: 16px;
                    pointer-events: none;
                    transition: .3s;
                }
    
                .footer-submit-btn {
                    height: 10%;
                    width: fit-content;
                    margin: 0 0 0 0px;
                    font-weight: 600;
                    font-size: .9em;
                    color: $footer-background;
                    background-color: white;
                    padding: 12px 20px;
                    border-radius: 50px;
                    transition: .35s;
                }
    
                .footer-submit-btn:hover {
                    background-color: #cacaca;
                    transition: .55s;
                }
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .footer {
        height: 55vh;
        width: 100vw;
        margin: 0;
        padding: 0;
        background-color: $footer-background;
        color: white;
        display: grid;
        grid-template-rows: 2fr 3fr 9fr 2.5fr;
        grid-template-columns: 10vw 1fr 1fr 10vw;
        font-family: $montserrat;
        z-index: 100;
    
        .footer-title {
            font-size: 1.2em;
            font-weight: 600;
            grid-row: 2 / 3;
            grid-column: 2 / 5;
            cursor: default;
            font-family: $raleway;
            background-color: $blue;
            height: fit-content;
            width: fit-content;
            color: white;
            padding: 6px 0px;
        }
    
        .footer-contact-us-details-container {
            grid-row: 3 / 4;
            grid-column: 2 / 3;
            cursor: default;
    
            .footer-contact-us {
                display: block;
                font-size: 1.1em;
                font-weight: 400;
                margin-bottom: 5vh;
            }
    
            .footer-contact-us-details {
                display: flex;
                margin-bottom: 2vh;
                font-size: .9em;
                font-weight: 300;
                align-items: center;
    
                .footer-details-icon {
                    height: 3vh;
                    width: 3vh;
                    margin-right: 20px;
                }
            }
        }
    
        .footer-copyright {
            grid-row: 4 / 5;
            grid-column: 3 / 5;
            font-size: .9em;
            font-weight: 300;
        }
    
        .footer-social-media {
            grid-row: 4 / 5;
            grid-column: 2 / 4;
            height: auto;
            width: auto;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
    
            .social-media-icon {
                height: 3.5vh;
                width: 3.5vh;
                margin-right: 1.8vh;
                cursor: pointer;
                transition: .4s ease-out;
            }
    
            .social-media-icon:hover {
                transform: rotate(360deg);
                transition: .7s ease-in-out;
            }
        }
    
        .footer-submit-container {
            grid-row: 3 / 4;
            grid-column: 3 / 5;
            margin-left: 15px;
    
            .footer-submit {
                height: 100%;
                width: 100%;
                margin: 0;
                padding: 0;
    
                .footer-submit-title {
                    display: block;
                    font-size: 1.1em;
                    font-weight: 400;
                    margin-bottom: 8vh;
                }
    
                .footer-form {
                    position: relative;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                }
    
                .footer-submit-input {
                    display: inline-block;
                    background-color: $footer-background;
                    border: 0px;
                    border-bottom: 1px solid white !important;
                    width: 40%;
                    color: white;
                    font-size: .9em;
                    position: relative;
                    padding-bottom: 5px;
                    top: 0;
                    height: 1.4em;
                    outline: none;
                }
    
                .footer-submit-input:focus ~ .input-label {
                    transform: translateY(-40px);
                    -webkit-transform: translateY(-40px);
                    transition: .3s;
                }
    
                .shrink {
                    transform: translateY(-40px);
                    -webkit-transform: translateY(-40px);
                }
    
                .input-label {
                    color: white;
                    font-size: .9em;
                    font-style: italic;
                    font-weight: 200;
                    position: absolute;
                    left: 0;
                    top: 16px;
                    pointer-events: none;
                    transition: .3s;
                }
    
                .footer-submit-btn {
                    height: 10%;
                    width: 20%;
                    margin: 0 0 0 0px;
                    font-weight: 600;
                    font-size: .9em;
                    color: $footer-background;
                    background-color: white;
                    padding: 12px 20px;
                    border-radius: 50px;
                    transition: .35s;
                }
    
                .footer-submit-btn:hover {
                    background-color: #cacaca;
                    transition: .55s;
                }
            }
        }
    }
}

@media only screen and (min-width: 1920px) {
    .footer {
        height: 55vh;
        width: 100vw;
        margin: 0;
        padding: 0;
        background-color: $footer-background;
        color: white;
        display: grid;
        grid-template-rows: 2fr 3fr 9fr 2.5fr;
        grid-template-columns: 10vw 3vw 1fr 1fr;
        font-family: $montserrat;
        z-index: 100;
    
        .footer-title {
            font-size: 1.7em;
            font-weight: 600;
            grid-row: 2 / 3;
            grid-column: 2 / 5;
            cursor: default;
            font-family: $raleway;
            background-color: $blue;
            height: fit-content;
            width: fit-content;
            color: white;
            padding: 6px 0px;
        }
    
        .footer-contact-us-details-container {
            grid-row: 3 / 4;
            grid-column: 3 / 4;
            cursor: default;
    
            .footer-contact-us {
                display: block;
                font-size: 1.5em;
                font-weight: 400;
                margin-bottom: 5vh;
            }
    
            .footer-contact-us-details {
                display: flex;
                margin-bottom: 2vh;
                font-size: 1.2em;
                font-weight: 300;
                align-items: center;
    
                .footer-details-icon {
                    height: 3vh;
                    width: 3vh;
                    margin-right: 20px;
                }
            }
        }
    
        .footer-copyright {
            grid-row: 4 / 5;
            grid-column: 4 / 5;
            font-size: 1.2em;
            font-weight: 300;
        }
    
        .footer-social-media {
            grid-row: 4 / 5;
            grid-column: 3 / 4;
            height: auto;
            width: auto;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
    
            .social-media-icon {
                height: 4vh;
                width: 4vh;
                margin-right: 2vh;
                cursor: pointer;
                transition: .4s ease-out;
            }
    
            .social-media-icon:hover {
                transform: rotate(360deg);
                transition: .7s ease-in-out;
            }
        }
    
        .footer-submit-container {
            grid-row: 3 / 4;
            grid-column: 4 / 5;
    
            .footer-submit {
                height: 100%;
                width: 100%;
                margin: 0;
                padding: 0;
    
                .footer-submit-title {
                    display: block;
                    font-size: 1.5em;
                    font-weight: 400;
                    margin-bottom: 8vh;
                }
    
                .footer-form {
                    position: relative;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                }
    
                .footer-submit-input {
                    display: inline-block;
                    background-color: $footer-background;
                    border: 0px;
                    border-bottom: 1px solid white !important;
                    width: 40%;
                    color: white;
                    font-size: 1.4em;
                    position: relative;
                    padding-bottom: 5px;
                    top: 0;
                    height: 1.4em;
                    outline: none;
                }
    
                .footer-submit-input:focus ~ .input-label {
                    transform: translateY(-40px);
                    -webkit-transform: translateY(-40px);
                    transition: .3s;
                }
    
                .shrink {
                    transform: translateY(-40px);
                    -webkit-transform: translateY(-40px);
                }
    
                .input-label {
                    color: white;
                    font-size: 1.2em;
                    font-style: italic;
                    font-weight: 200;
                    position: absolute;
                    left: 0;
                    top: 16px;
                    pointer-events: none;
                    transition: .3s;
                }
    
                .footer-submit-btn {
                    height: 10%;
                    width: 20%;
                    margin: 0 0 0 0px;
                    font-weight: 600;
                    font-size: 1.3em;
                    color: $footer-background;
                    background-color: white;
                    padding: 16px 24px;
                    border-radius: 50px;
                    transition: .35s;
                }
    
                .footer-submit-btn:hover {
                    background-color: #cacaca;
                    transition: .55s;
                }
            }
        }
    }
}