// $main-blue: #1472B6;
$main-blue: rgb(46,67, 151);
$blue: rgb(46,67, 151);
// $main-green: rgb(151, 201, 59);
$main-green: rgb(46,67, 151);
$blue-hover: hsl(205, 80%, 25%);
$playfair: 'Playfair Display', serif;
$raleway: 'Raleway', sans-serif;
$montserrat: 'Montserrat', sans-serif;

* { 
    ::-webkit-scrollbar {
        width: 8px;               /* width of the entire scrollbar */
        background-color: transparent;
    }
    
    ::-webkit-scrollbar-track {
        background-color: white;     /* color of the tracking area */
        border: none;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: rgb(46,67, 151);    /* color of the scroll thumb */
        border: none;
    }
}

.loading-curtain-1 {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: $blue;
    z-index: 99999999999;
    top: 0;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform-origin: top;
    -webkit-transform-origin: top;
}

.loading-curtain-2 {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: #96C93C;
    z-index: 99999999999;
    top: 0;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform-origin: top;
    -webkit-transform-origin: top;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    html, body {
        height: 100%;
        width: 100vw;
        overflow-x: hidden;
        overflow-y: auto;
        background: white;
        margin: 0;
        padding: 0;
        position: fixed; //important as fuck brah
    }
    
    .landing-page {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        background: white;
    
        
        .header {
            z-index: 999999;
            position: fixed;
        }
        
        .section-1 {
            height: fit-content;
            width: 100vw;
            margin: 0;
            padding: 0;
            // padding-top: 40px !important;
            overflow-y: hidden;
            overflow-x: hidden;
            display: grid;
            grid-template-rows: 24vh 1fr 16vh;
            grid-template-columns: 13vw 50px 3fr 2fr 13vw;
            position: relative;
            
            .landing-img-1 {
                grid-row: 2 / 4;
                grid-column: 4 / 5;
                height: auto;
                width: auto;
                justify-self: center;
                z-index: 10;
            }
    
            .vertical-div {
                grid-row: 1 / 4;
                grid-column: 1 / 2;
                height: 100%;
                width: 150%;
                background-color: #f5f5f5;
                pointer-events: none;
                z-index: 1;
            }
    
            .overlay-text {
                font-family: $playfair;
                font-size: 12em;
                font-weight: 800;
                color: #f5f5f5;
                pointer-events: none;
                grid-row: 2 / 3;
                grid-column-start: 3;
                position: absolute;
                margin-left: 10vw;
                margin-top: 40vh;
                z-index: 1;
            }
    
            .vertical-bar {
                grid-row: 2 / 3;
                grid-column: 2 / 3;
                height: 100%;
                width: 3px;
                background-color: black;
                z-index: 10;
            }
    
            .slogan-container {
                grid-row: 2 / 3;
                grid-column: 2 / 5;
                height: auto;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-top: 5%;
                // padding-left: 2%;
                z-index: 10;
    
                .slogan-big {
                    font-family: $playfair;
                    font-weight: 800;
                    font-size: 5em;
                    color: black;
                    padding: 0;
                    text-align: center;
    
                    .slogan-big-blue {
                        color: $blue;
                    }
                }
    
                .slogan-mid {
                    font-family: $playfair;
                    font-weight: 500;
                    font-size: 2.7em;
                    color: black;
                    margin: 0;
                    padding: 0;
    
                    .slogan-small-blue {
                        color: $blue;
                    }
                }
    
                .slogan-small {
                    color: $blue;
                    font-family: $montserrat;
                    width: 100%;
                    margin-top: 5vh;
                    font-weight: 400;
                    font-size: 1em;
                    line-height: 38px;
                }
            }
    
            .s1-button {
                grid-row: 3 / 4;
                grid-column: 2 / 4;
                font-family: $playfair;
                height: 50px;
                width: 160px;
                margin: 0 0 0 2%;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 10;
                text-transform: none;
                margin-top: 15px;
            }
    
            .s1-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
        }
    
        .section-2 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            margin-bottom: 15px;
            display: grid;
            grid-template-rows: 65px 270px 60px 90vw;
            grid-template-columns: 13vw 1fr 13vw;
    
            .landing-img-2 {
                grid-row: 4 / 4;  
                grid-column: 2 / 2;
                height: 100%;
                width: auto;
                margin-top: 15px;
            }
    
            .s2-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
    
                .s2-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 1.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 3vh;
                    z-index: 100;
                    position: relative;
                }
    
                .s2-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s2-content {
                grid-row: 2 / 2;  
                grid-column: 2 / 4;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                line-height: 40px;
                width: 80%;
                height: fit-content;
            }
    
            .s2-button {
                grid-row: 3 / 3;
                grid-column: 2 / 2;
                font-family: $playfair;
                height: 50px;
                width: 160px;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
                text-transform: none;
                margin-top: 10px;
            }
    
            .s2-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
    
    
        }
    
        .section-3 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            margin-bottom: 15px;
            display: grid;
            grid-template-rows: 65px 270px 80px 90vw;
            grid-template-columns: 13vw 1fr 13vw;
    
            .landing-img-3 {
                grid-row: 4 / 4;  
                grid-column: 2 / 2;
                height: 100%;
            }
    
            .s3-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
    
                .s3-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 1.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 2vw;
                    z-index: 100;
                    position: relative;
                }
    
                .s3-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: right;
                    -webkit-transform-origin: right;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s3-content {
                grid-row: 2 / 2;  
                grid-column: 2 / 2;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                line-height: 40px;
                width: fit-content;
                height: fit-content;
                // margin-top: 10vh;
    
    
                .content-s {
                    display: block;
                    width: fit-content;
                }
            }
    
            .s3-button {
                grid-row: 3 / 3;
                grid-column: 2 / 2;
                font-family: $playfair;
                height: 50px;
                width: 160px;
                // margin: 0 0 0 5vh;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
                text-transform: none;
            }
    
            .s3-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
        }
    
    
        .section-4 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 5vh 1fr 10vh;
            grid-template-columns: 13vw 1fr 13vw;
            position: relative;
            overflow: hidden;
    
            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;
    
                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 1.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 29vw;
                    z-index: 100;
                    position: relative;
                }
    
                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s4-content {
                grid-row: 2 / 4;  
                grid-column: 2 / 2;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
    
    
                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;
    
                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }
    
            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }

        .section-5 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1fr 10vh;
            grid-template-columns: 10vw 3fr 10vw;
            position: relative;
            overflow: hidden;

            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;

                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 1.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 19vw;
                    z-index: 100;
                    position: relative;
                }

                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }

            .s4-content {
                grid-row: 2 / 3;  
                grid-column: 2 / 2;
                display: flex;
                color: black;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                // width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
                justify-content: space-between;


                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;

                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }

            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }

        .section-6 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1fr 10vh;
            grid-template-columns: 10vw 3fr 10vw;
            position: relative;
            overflow: hidden;

            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;

                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 1.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 29vw;
                    z-index: 100;
                    position: relative;
                }

                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }

            .s4-content {
                grid-row: 2 / 3;  
                grid-column: 2 / 2;
                display: flex;
                color: $blue;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                // width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
                justify-content: center;


                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;

                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }

            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    html, body {
        height: 100%;
        width: 100vw;
        overflow-x: hidden;
        overflow-y: auto;
        background: white;
        margin: 0;
        padding: 0;
        position: fixed; //important as fuck brah
    }
    
    .landing-page {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        background: white;
    
        
        .header {
            z-index: 999999;
            position: fixed;
        }
        
        .section-1 {
            height: fit-content;
            width: 100vw;
            margin: 0;
            padding: 0;
            // padding-top: 40px !important;
            overflow-y: hidden;
            overflow-x: hidden;
            display: grid;
            grid-template-rows: 24vh 1fr 26vh;
            grid-template-columns: 15vw 50px 3fr 2fr 10vw;
            position: relative;
            
            .landing-img-1 {
                grid-row: 2 / 4;
                grid-column: 4 / 5;
                height: auto;
                width: auto;
                justify-self: center;
                z-index: 10;
            }
    
            .vertical-div {
                grid-row: 1 / 4;
                grid-column: 1 / 2;
                height: 100%;
                width: 150%;
                background-color: #f5f5f5;
                pointer-events: none;
                z-index: 1;
            }
    
            .overlay-text {
                font-family: $playfair;
                font-size: 12em;
                font-weight: 800;
                color: #f5f5f5;
                pointer-events: none;
                grid-row: 2 / 3;
                grid-column-start: 3;
                position: absolute;
                margin-left: 10vw;
                margin-top: 40vh;
                z-index: 1;
            }
    
            .vertical-bar {
                grid-row: 2 / 3;
                grid-column: 2 / 3;
                height: 100%;
                width: 3px;
                background-color: black;
                z-index: 10;
            }
    
            .slogan-container {
                grid-row: 2 / 3;
                grid-column: 2 / 5;
                height: auto;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-top: 5%;
                padding-left: 2%;
                z-index: 10;
    
                .slogan-big {
                    font-family: $playfair;
                    font-weight: 800;
                    font-size: 6em;
                    color: black;
                    padding: 0;
    
                    .slogan-big-blue {
                        color: $blue;
                    }
                }
    
                .slogan-mid {
                    font-family: $playfair;
                    font-weight: 500;
                    font-size: 2.7em;
                    color: black;
                    margin: 0;
                    padding: 0;
    
                    .slogan-small-blue {
                        color: $blue;
                    }
                }
    
                .slogan-small {
                    color: $blue;
                    font-family: $montserrat;
                    width: 70%;
                    margin-top: 5vh;
                    font-weight: 400;
                    font-size: 1.2em;
                    line-height: 38px;
                }
            }
    
            .s1-button {
                grid-row: 3 / 4;
                grid-column: 2 / 5;
                font-family: $playfair;
                height: 25%;
                width: 30%;
                margin: 0 0 0 2%;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 10;
                text-transform: none;
            }
    
            .s1-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
        }
    
        .section-2 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 5vh 40vh 20vh;
            grid-template-columns: 20vw 2fr 3fr 20vw;
    
            .landing-img-2 {
                grid-row: 5 / 5;  
                grid-column: 3 / 3;
                height: 70%;
                width: auto;
            }
    
            .s2-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
    
                .s2-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 2em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 21vh;
                    z-index: 100;
                    position: relative;
                }
    
                .s2-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s2-content {
                grid-row: 2 / 2;  
                grid-column: 3 / 4;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: 1em;
                font-weight: 400;
                line-height: 40px;
                width: 80%;
                height: fit-content;
            }
    
            .s2-button {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                font-family: $playfair;
                height: 32%;
                width: 37%;
                // margin: 0 0 0 5vh;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
                text-transform: none;
            }
    
            .s2-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
    
    
        }
    
        .section-3 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 5vh 40vh 20vh;
            grid-template-columns: 20vw 3fr 2fr 20vw;
    
            .landing-img-3 {
                grid-row: 5 / 5;  
                grid-column: 2 / 2;
                height: 73%;
            }
    
            .s3-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
    
                .s3-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 2em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 30vw;
                    z-index: 100;
                    position: relative;
                }
    
                .s3-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: right;
                    -webkit-transform-origin: right;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s3-content {
                grid-row: 3 / 4;  
                grid-column: 2 / 3;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: 1.2em;
                font-weight: 400;
                line-height: 40px;
                width: fit-content;
                height: fit-content;
    
    
                .content-s {
                    display: block;
                    width: fit-content;
                }
            }
    
            .s3-button {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                font-family: $playfair;
                height: 32%;
                width: 35%;
                // margin: 0 0 0 5vh;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
                text-transform: none;
            }
    
            .s3-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
        }
    
    
        .section-4 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 5vh 1fr 10vh;
            grid-template-columns: 20vw 4fr 3fr 20vw;
            position: relative;
            overflow: hidden;
    
            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;
    
                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 2em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 45vw;
                    z-index: 100;
                    position: relative;
                }
    
                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s4-content {
                grid-row: 2 / 4;  
                grid-column: 2 / 4;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: 1.2em;
                font-weight: 400;
                width: fit-content;
                height: fit-content;
                line-height: 40px;
                z-index: 100;
    
    
                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;
    
                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }
    
            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }
                
        .section-5 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1fr 10vh;
            grid-template-columns: 10vw 3fr 10vw;
            position: relative;
            overflow: hidden;

            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;

                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 2em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 30vw;
                    z-index: 100;
                    position: relative;
                }

                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }

            .s4-content {
                grid-row: 2 / 3;  
                grid-column: 2 / 2;
                display: flex;
                color: black;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                // width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
                justify-content: space-between;


                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;

                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }

            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }
                
        .section-6 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1fr 10vh;
            grid-template-columns: 10vw 3fr 10vw;
            position: relative;
            overflow: hidden;

            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;

                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 2em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 45vw;
                    z-index: 100;
                    position: relative;
                }

                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }

            .s4-content {
                grid-row: 2 / 3;  
                grid-column: 2 / 2;
                display: flex;
                color: $blue;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                // width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
                justify-content: space-between;


                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;

                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }

            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    html, body {
        height: 100%;
        width: 100vw;
        overflow-x: hidden;
        overflow-y: auto;
        background: white;
        margin: 0;
        padding: 0;
        position: fixed; //important as fuck brah
    }
    
    .landing-page {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        background: white;
    
        
        .header {
            z-index: 999999;
            position: fixed;
        }
        
        .section-1 {
            height: fit-content;
            width: 100vw;
            margin: 0;
            padding: 0;
            // padding-top: 40px !important;
            overflow-y: auto;
            overflow-x: hidden;
            display: grid;
            grid-template-rows: 24vh 1fr 15vw;
            grid-template-columns: 13vw 1fr 13vw;
            position: relative;
            
            .landing-img-1 {
                grid-row: 2 / 4;
                grid-column: 4 / 5;
                height: auto;
                width: auto;
                justify-self: center;
                z-index: 10;
            }
    
            .vertical-div {
                grid-row: 1 / 4;
                grid-column: 1 / 2;
                height: 100%;
                width: 150%;
                background-color: #f5f5f5;
                pointer-events: none;
                z-index: 1;
            }
    
            .overlay-text {
                font-family: $playfair;
                font-size: 12em;
                font-weight: 800;
                color: #f5f5f5;
                pointer-events: none;
                grid-row: 2 / 3;
                grid-column-start: 3;
                position: absolute;
                margin-left: 10vw;
                margin-top: 40vh;
                z-index: 1;
            }
    
            .vertical-bar {
                grid-row: 2 / 3;
                grid-column: 2 / 3;
                height: 100%;
                width: 3px;
                background-color: black;
                z-index: 10;
            }
    
            .slogan-container {
                grid-row: 2 / 3;
                grid-column: 2 / 4;
                height: auto;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-top: 0px !important;
                padding-left: 0px !important;
                z-index: 10;
    
                .slogan-big {
                    font-family: $playfair;
                    font-weight: 800;
                    font-size: 6em;
                    color: black;
                    padding: 0;
    
                    .slogan-big-blue {
                        color: $blue;
                    }
                }
    
                .slogan-mid {
                    font-family: $playfair;
                    font-weight: 500;
                    font-size: 2.7em;
                    color: black;
                    margin: 0;
                    padding: 0;
    
                    .slogan-small-blue {
                        color: $blue;
                    }
                }
    
                .slogan-small {
                    color: $blue;
                    font-family: $montserrat;
                    width: 70%;
                    margin-top: 5vh;
                    font-weight: 400;
                    font-size: 1.2em;
                    line-height: 38px;
                }
            }
    
            .s1-button {
                grid-row: 3 / 4;
                grid-column: 2 / 3;
                font-family: $playfair;
                height: 50px;
                width: 160px;
                margin: 30px 0px 0px 0px  !important;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 10;
                text-transform: none;
                padding: 10px
            }
    
            .s1-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
        }
    
        .section-2 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 65px 270px 80px 90vw;
            grid-template-columns: 13vw 1fr 13vw;
    
            .landing-img-2 {
                grid-row: 4 / 4;  
                grid-column: 2 / 2;
                height: auto;
            }
    
            .s2-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
    
                .s2-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 2.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 27vh;
                    z-index: 100;
                    position: relative;
                }
    
                .s2-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s2-content {
                grid-row: 2 / 2;  
                grid-column: 2 / 3;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: 1.2em;
                font-weight: 400;
                line-height: 40px;
                width: 80%;
                margin-left: 20%;
                height: fit-content;
                margin-top: 20px;
            }
    
            .s2-button {
                grid-row: 3 / 3;
                grid-column: 2 / 2;
                font-family: $playfair;
                height: 50px;
                width: 160px;
                margin: 0 0 0 0vh;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid black;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
                text-transform: none;
                margin-left: calc(100% - 160px);
            }
    
            .s2-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
    
    
        }
    
        .section-3 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 65px 270px 80px 90vw;
            grid-template-columns: 13vw 1fr 13vw;

            .landing-img-3 {
                grid-row: 4 / 4;  
                grid-column: 2 / 2;
                height: 100%;
            }
    
            .s3-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
    
                .s3-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 2.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 33vw;
                    z-index: 100;
                    position: relative;
                }
    
                .s3-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: right;
                    -webkit-transform-origin: right;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s3-content {
                grid-row: 2 / 2;
                grid-column: 2 / 2;
                margin-top: 15px;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: 1.2em;
                font-weight: 400;
                line-height: 40px;
                width: fit-content;
                height: fit-content;
    
    
                .content-s {
                    display: block;
                    width: fit-content;
                }
            }
    
            .s3-button {
                grid-row: 3 / 3;
                grid-column: 2 / 2;
                font-family: $playfair;
                height: 50px;
                width: 160px;
                // margin: 0 0 0 5vh;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
                text-transform: none;
            }
    
            .s3-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
        }
    
        .section-4 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 5vh 1fr 10vh;
            grid-template-columns: 13vw 1fr 13vw;
            position: relative;
            overflow: hidden;
    
            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;
    
                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 3.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 27vh;
                    z-index: 100;
                    position: relative;
                }
    
                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s4-content {
                grid-row: 2 / 4;  
                grid-column: 2 / 2;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: 1.2em;
                font-weight: 400;
                width: fit-content;
                height: fit-content;
                line-height: 40px;
                z-index: 100;
    
    
                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;
    
                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }
    
            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }
         
        .section-5 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1fr 10vh;
            grid-template-columns: 10vw 3fr 10vw;
            position: relative;
            overflow: hidden;

            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;

                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 3.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 33vw;
                    z-index: 100;
                    position: relative;
                }

                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }

            .s4-content {
                grid-row: 2 / 3;  
                grid-column: 2 / 2;
                display: flex;
                color: black;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                // width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
                justify-content: space-between;


                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;

                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }

            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }
         
        .section-6 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1fr 10vh;
            grid-template-columns: 10vw 3fr 10vw;
            position: relative;
            overflow: hidden;

            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;

                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 3.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 27vh;
                    z-index: 100;
                    position: relative;
                }

                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }

            .s4-content {
                grid-row: 2 / 3;  
                grid-column: 2 / 2;
                display: flex;
                color: $blue;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                // width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
                justify-content: space-between;


                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;

                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }

            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    html, body {
        height: 100%;
        width: 100vw;
        overflow-x: hidden;
        overflow-y: auto;
        background: white;
        margin: 0;
        padding: 0;
        position: fixed; //important as fuck brah
    }
    
    .landing-page {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        background: white;
        
        .section-1 {
            height: 85vh;
            width: 100vw;
            margin: 0;
            padding: 0;
            // padding-top: 40px !important;
            overflow-y: hidden;
            overflow-x: hidden;
            display: grid;
            grid-template-rows: 20vw 1fr 26vh;
            grid-template-columns: 10vw 50px 3fr 2fr 10vw;
            position: relative;
            
            .landing-img-1 {
                grid-row: 2 / 4;
                grid-column: 4 / 5;
                height: auto;
                width: auto;
                justify-self: center;
                z-index: 10;
                max-height: 100%;
                max-width: 100%;
            }
    
            .vertical-div {
                grid-row: 1 / 4;
                grid-column: 1 / 2;
                height: 100%;
                width: 150%;
                background-color: #f5f5f5;
                pointer-events: none;
                z-index: 1;
            }
    
            .overlay-text {
                font-family: $playfair;
                font-size: 6em;
                font-weight: 800;
                color: #f5f5f5;
                pointer-events: none;
                grid-row: 2 / 3;
                grid-column-start: 3;
                position: absolute;
                margin-left: 10vw;
                margin-top: 40vh;
                z-index: 1;
            }
    
            .vertical-bar {
                grid-row: 2 / 3;
                grid-column: 2 / 3;
                height: 100%;
                width: 3px;
                background-color: black;
                z-index: 10;
            }
    
            .slogan-container {
                grid-row: 2 / 3;
                grid-column: 3 / 4;
                height: auto;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-top: 5%;
                padding-left: 2%;
                z-index: 10;
    
                .slogan-big {
                    font-family: $playfair;
                    font-weight: 800;
                    font-size: 3.5em;
                    color: black;
                    padding: 0;
    
                    .slogan-big-blue {
                        color: $blue;
                    }
                }
    
                .slogan-mid {
                    font-family: $playfair;
                    font-weight: 500;
                    font-size: 1.8em;
                    color: black;
                    margin: 0;
                    padding: 0;
    
                    .slogan-small-blue {
                        color: $blue;
                    }
                }
    
                .slogan-small {
                    color: $blue;
                    font-family: $montserrat;
                    width: 70%;
                    margin-top: 5vh;
                    font-weight: 400;
                    font-size: .9em;
                    line-height: 27px;
                }
            }
    
            .s1-button {
                grid-row: 3 / 4;
                grid-column: 3 / 4;
                font-family: $playfair;
                height: 22%;
                width: 30%;
                margin: 0 0 0 2%;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 10;
                text-transform: none;
            }
    
            .s1-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
        }
    
        .section-2 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 40vh 8vh;
            grid-template-columns: 15vw 2fr 3fr 15vw;
    
            .landing-img-2 {
                grid-row: 2 / 2;  
                grid-column: 2 / 2;
                height: 100%;
                width: auto;
            }
    
            .s2-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
    
                .s2-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 1.8em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 27vh;
                    z-index: 100;
                    position: relative;
                }
    
                .s2-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s2-content {
                grid-row: 2 / 2;  
                grid-column: 3 / 3;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: .9em;
                font-weight: 400;
                line-height: 25px;
                width: 80%;
                height: fit-content;
                margin-left: 20%;
                margin-top: 5vh;
            }
    
            .s2-button {
                grid-row: 3 / 3;
                grid-column: 3 / 3;
                font-family: $playfair;
                height: 50px;
                width: 160px;
                margin: 0 0 0 5vh;
                font-weight: 700;
                font-size: .9em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
                text-transform: none;
            }
    
            .s2-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
    
    
        }
    
        .section-3 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 40vh 8vh;
            grid-template-columns: 15vw 3fr 2fr 15vw;
    
            .landing-img-3 {
                grid-row: 2 / 2;  
                grid-column: 3 / 3;
            }
    
            .s3-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
    
                .s3-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 1.8em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 45vw;
                    z-index: 100;
                    position: relative;
                }
    
                .s3-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: right;
                    -webkit-transform-origin: right;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s3-content {
                grid-row: 2 / 2;  
                grid-column: 2 / 2;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: .9em;
                font-weight: 400;
                line-height: 27px;
                width: fit-content;
                height: fit-content;
                margin-top: 3vh;
                margin-right: 20%;
    
    
                .content-s {
                    display: block;
                    width: fit-content;
                }
            }
    
            .s3-button {
                grid-row: 3 / 3;
                grid-column: 2 / 2;
                font-family: $playfair;
                height: 50px;
                width: 160px;
                font-weight: 700;
                font-size: .9em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
                text-transform: none;
            }
    
            .s3-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
        }
    
        .section-4 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1vh 1fr 10vh;
            grid-template-columns: 13vw 1fr 13vw;
            position: relative;
            overflow: hidden;
    
            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;
    
                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 1.8em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 27vh;
                    z-index: 100;
                    position: relative;
                }
    
                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s4-content {
                grid-row: 3 / 3;  
                grid-column: 2 / 2;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: .9em;
                font-weight: 400;
                width: fit-content;
                margin: auto;
                height: fit-content;
                line-height: 30px;
                z-index: 100;
    
    
                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;
    
                    .content-index {
                        font-size: 1.8em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }
    
            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }

        .section-5 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1fr 10vh;
            grid-template-columns: 10vw 3fr 10vw;
            position: relative;
            overflow: hidden;

            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;

                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 1.8em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 45vw;
                    z-index: 100;
                    position: relative;
                }

                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }

            .s4-content {
                grid-row: 2 / 3;  
                grid-column: 2 / 2;
                display: flex;
                color: black;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                // width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
                justify-content: space-between;


                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;

                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }

            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }

        .section-6 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1fr 10vh;
            grid-template-columns: 10vw 3fr 10vw;
            position: relative;
            overflow: hidden;

            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;

                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 1.8em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 27vh;
                    z-index: 100;
                    position: relative;
                }

                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }

            .s4-content {
                grid-row: 2 / 3;  
                grid-column: 2 / 2;
                display: flex;
                color: $blue;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                // width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
                justify-content: space-between;


                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;

                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }

            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    html, body {
        height: 100%;
        width: 100vw;
        overflow-x: hidden;
        overflow-y: auto;
        background: white;
        margin: 0;
        padding: 0;
        position: fixed; //important as fuck brah
    }
    
    .landing-page {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        background: white;
        
        .section-1 {
            height: 100vh;
            width: 100vw;
            margin: 0;
            padding: 0;
            // padding-top: 40px !important;
            overflow-y: hidden;
            overflow-x: hidden;
            display: grid;
            grid-template-rows: 27vh 1fr 26vh;
            grid-template-columns: 15vw 50px 3fr 2fr 10vw;
            position: relative;
            
            .landing-img-1 {
                grid-row: 2 / 4;
                grid-column: 4 / 5;
                max-height: auto;
                max-width: auto;
                justify-self: center;
                z-index: 10;
            }
    
            .vertical-div {
                grid-row: 1 / 4;
                grid-column: 1 / 2;
                height: 100%;
                width: 150%;
                background-color: #f5f5f5;
                pointer-events: none;
                z-index: 1;
            }
    
            .overlay-text {
                font-family: $playfair;
                font-size: 8em;
                font-weight: 800;
                color: #f5f5f5;
                pointer-events: none;
                grid-row: 2 / 3;
                grid-column-start: 3;
                position: absolute;
                margin-left: 10vw;
                margin-top: 40vh;
                z-index: 1;
            }
    
            .vertical-bar {
                grid-row: 2 / 3;
                grid-column: 2 / 3;
                height: 100%;
                width: 3px;
                background-color: black;
                z-index: 10;
            }
    
            .slogan-container {
                grid-row: 2 / 3;
                grid-column: 3 / 4;
                height: auto;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-top: 5%;
                padding-left: 2%;
                z-index: 10;
    
                .slogan-big {
                    font-family: $playfair;
                    font-weight: 800;
                    font-size: 4em;
                    color: black;
                    padding: 0;
    
                    .slogan-big-blue {
                        color: $blue;
                    }
                }
    
                .slogan-mid {
                    font-family: $playfair;
                    font-weight: 500;
                    font-size: 2em;
                    color: black;
                    margin: 0;
                    padding: 0;
    
                    .slogan-small-blue {
                        color: $blue;
                    }
                }
    
                .slogan-small {
                    color: $blue;
                    font-family: $montserrat;
                    width: 70%;
                    margin-top: 5vh;
                    font-weight: 400;
                    font-size: 1em;
                    line-height: 38px;
                }
            }
    
            .s1-button {
                grid-row: 3 / 4;
                grid-column: 3 / 4;
                font-family: $playfair;
                height: 22%;
                width: 30%;
                margin: 0 0 0 2%;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 10;
            }
    
            .s1-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
        }
    
        .section-2 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            padding-top: 5vh;
            display: grid;
            grid-template-rows: 10vh 5vh 40vh 20vh;
            grid-template-columns: 20vw 2fr 3fr 20vw;
    
            .landing-img-2 {
                grid-row: 3 / 5;  
                grid-column: 2 / 3;
                height: 70%;
                width: auto;
            }
    
            .s2-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
    
                .s2-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 2em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 27vh;
                    z-index: 100;
                    position: relative;
                }
    
                .s2-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s2-content {
                grid-row: 3 / 4;  
                grid-column: 3 / 4;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: 1em;
                font-weight: 400;
                line-height: 30px;
                width: 90%;
                height: fit-content;
                margin-left: 5vh;
                margin-top: 5vh;
            }
    
            .s2-button {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                font-family: $playfair;       
                text-transform: none;         
                height: 32%;
                width: 35%;
                margin: 0 0 0 5vh;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
            }
    
            .s2-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
    
    
        }
    
        .section-3 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 5vh 40vh 20vh;
            grid-template-columns: 20vw 3fr 2fr 20vw;
    
            .landing-img-3 {
                grid-row: 3 / 5;  
                grid-column: 3 / 4;
                height: 100%;
                max-height: 100%;
                max-width: 100%;
            }
    
            .s3-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
    
                .s3-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 2em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 45vw;
                    z-index: 100;
                    position: relative;
                }
    
                .s3-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: right;
                    -webkit-transform-origin: right;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s3-content {
                grid-row: 3 / 4;  
                grid-column: 2 / 3;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: 1em;
                font-weight: 400;
                line-height: 35px;
                width: fit-content;
                height: fit-content;
                margin-top: 3vh;
                margin-left: 5vh;
    
    
                .content-s {
                    display: block;
                    width: fit-content;
                }
            }
    
            .s3-button {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                font-family: $playfair;
                height: 32%;
                width: 35%;
                margin: 0 0 0 5vh;
                font-weight: 700;
                font-size: 1em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
                margin-left: 5vh;
                text-transform: none;
            }
    
            .s3-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
        }
    
        .section-4 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 5vh 1fr 10vh;
            grid-template-columns: 20vw 4fr 3fr 20vw;
            position: relative;
            overflow: hidden;
    
            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;
    
                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 2em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 27vh;
                    z-index: 100;
                    position: relative;
                }
    
                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s4-content {
                grid-row: 3 / 4;  
                grid-column: 2 / 4;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: 1.1em;
                font-weight: 400;
                width: fit-content;
                height: fit-content;
                margin-left: 15vh;
                margin-right: 15vh;
                line-height: 40px;
                z-index: 100;
    
    
                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;
    
                    .content-index {
                        font-size: 2em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }
    
            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }

          
        .section-5 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1fr 10vh;
            grid-template-columns: 10vw 3fr 10vw;
            position: relative;
            overflow: hidden;

            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;

                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 2em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 45vw;
                    z-index: 100;
                    position: relative;
                }

                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }

            .s4-content {
                grid-row: 2 / 3;  
                grid-column: 2 / 2;
                display: flex;
                color: black;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                // width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
                justify-content: space-between;


                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;

                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }

            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }
          
        .section-6 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1fr 10vh;
            grid-template-columns: 10vw 3fr 10vw;
            position: relative;
            overflow: hidden;

            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;

                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 2em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 27vh;
                    z-index: 100;
                    position: relative;
                }

                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }

            .s4-content {
                grid-row: 2 / 3;  
                grid-column: 2 / 2;
                display: flex;
                color: $blue;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                // width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
                justify-content: space-between;


                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;

                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }

            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }
    }
}

@media only screen and (min-width: 1920px) {
    html, body {
        height: 100%;
        width: 100vw;
        overflow-x: hidden;
        overflow-y: auto;
        background: white;
        margin: 0;
        padding: 0;
        position: fixed; //important as fuck brah
    }
    
    .landing-page {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        background: white;
    
        
        .header {
            z-index: 999999;
            position: fixed;
        }
        
        .section-1 {
            height: 100vh;
            width: 100vw;
            margin: 0;
            padding: 0;
            // padding-top: 40px !important;
            overflow-y: auto;
            overflow-x: hidden;
            display: grid;
            grid-template-rows: 24vh 1fr 26vh;
            grid-template-columns: 15vw 50px 3fr 2fr 10vw;
            position: relative;
            
            .landing-img-1 {
                grid-row: 2 / 4;
                grid-column: 4 / 5;
                // max-height: 100%;
                // max-width: 100%;
                width: auto;
                height: auto;
                justify-self: center;
                z-index: 10;
            }
    
            .vertical-div {
                grid-row: 1 / 4;
                grid-column: 1 / 2;
                height: 100%;
                width: 150%;
                background-color: #f5f5f5;
                pointer-events: none;
                z-index: 1;
            }
    
            .overlay-text {
                font-family: $playfair;
                font-size: 12em;
                font-weight: 800;
                color: #f5f5f5;
                pointer-events: none;
                grid-row: 2 / 3;
                grid-column-start: 3;
                position: absolute;
                margin-left: 10vw;
                margin-top: 40vh;
                z-index: 1;
            }
    
            .vertical-bar {
                grid-row: 2 / 3;
                grid-column: 2 / 3;
                height: 100%;
                width: 3px;
                background-color: black;
                z-index: 10;
            }
    
            .slogan-container {
                grid-row: 2 / 3;
                grid-column: 3 / 4;
                height: auto;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-top: 5%;
                padding-left: 2%;
                z-index: 10;
    
                .slogan-big {
                    font-family: $playfair;
                    font-weight: 800;
                    font-size: 6em;
                    color: black;
                    padding: 0;
    
                    .slogan-big-blue {
                        color: $blue;
                    }
                }
    
                .slogan-mid {
                    font-family: $playfair;
                    font-weight: 500;
                    font-size: 2.7em;
                    color: black;
                    margin: 0;
                    padding: 0;
    
                    .slogan-small-blue {
                        color: $blue;
                    }
                }
    
                .slogan-small {
                    color: $blue !important;
                    font-family: $montserrat;
                    width: 70%;
                    margin-top: 5vh;
                    font-weight: 400;
                    font-size: 1.2em;
                    line-height: 38px;
                }
            }
    
            .s1-button {
                grid-row: 3 / 4;
                grid-column: 3 / 4;
                font-family: $playfair;
                height: 22%;
                width: 30%;
                margin: 0 0 0 2%;
                font-weight: 700;
                font-size: 1.5em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 10;
            }
    
            .s1-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
        }
    
        .section-2 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 5vh 40vh 20vh;
            grid-template-columns: 20vw 2fr 3fr 20vw;
    
            .landing-img-2 {
                grid-row: 3 / 5;  
                grid-column: 2 / 3;
                
            }
    
            .s2-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
    
                .s2-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 3.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 27vh;
                    z-index: 100;
                    position: relative;
                }
    
                .s2-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s2-content {
                grid-row: 2 / 4;  
                grid-column: 3 / 4;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: 1.2em;
                font-weight: 400;
                line-height: 40px;
                width: 80%;
                height: fit-content;
                margin-left: 5vh;
                margin-top: 10vh;
            }
    
            .s2-button {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                font-family: $playfair;
                height: 32%;
                width: 35%;
                margin: 0 0 0 5vh;
                font-weight: 700;
                font-size: 1.5em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
                text-transform: none;
            }
    
            .s2-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
    
    
        }
    
        .section-3 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 5vh 40vh 20vh;
            grid-template-columns: 20vw 3fr 2fr 20vw;
    
            .landing-img-3 {
                grid-row: 3 / 5;  
                grid-column: 3 / 4;
                height: auto;
                width: 100%;
                max-height: 100%;
                max-width: 100%;
            }
    
            .s3-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
    
                .s3-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 3.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 45vw;
                    z-index: 100;
                    position: relative;
                }
    
                .s3-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: right;
                    -webkit-transform-origin: right;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s3-content {
                grid-row: 3 / 4;  
                grid-column: 2 / 3;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: 1.2em;
                font-weight: 400;
                line-height: 40px;
                width: fit-content;
                height: fit-content;
                margin-top: 10vh;
                margin-left: 15vh;
    
    
                .content-s {
                    display: block;
                    width: fit-content;
                }
            }
    
            .s3-button {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                font-family: $playfair;
                height: 32%;
                width: 35%;
                margin: 0 0 0 5vh;
                font-weight: 700;
                font-size: 1.5em;
                color: $blue;
                background-color: white;
                transition: .35s;
                border: 2px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 100;
                margin-left: 15vh;
                text-transform: none;
            }
    
            .s3-button:hover {
                background-color: #eaeaea;
                transition: .55s;
            }
        }
    
    
        .section-4 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 5vh 1fr 10vh;
            grid-template-columns: 20vw 4fr 3fr 20vw;
            position: relative;
            overflow: hidden;
    
            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;
    
                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 3.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 27vh;
                    z-index: 100;
                    position: relative;
                }
    
                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }
    
            .s4-content {
                grid-row: 3 / 4;  
                grid-column: 2 / 4;
                display: block;
                color: $blue;
                font-family: $montserrat;
                font-size: 1.2em;
                font-weight: 400;
                width: fit-content;
                height: fit-content;
                margin-left: 15vh;
                margin-right: 15vh;
                line-height: 40px;
                z-index: 100;
    
    
                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;
    
                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }
    
            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }

                
        .section-5 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1fr 10vh;
            grid-template-columns: 10vw 3fr 10vw;
            position: relative;
            overflow: hidden;

            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;

                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 3.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 45vw;
                    z-index: 100;
                    position: relative;
                }

                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }

            .s4-content {
                grid-row: 2 / 3;  
                grid-column: 2 / 2;
                display: flex;
                color: black;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                // width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
                justify-content: space-between;


                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;

                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }

            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }

                
        .section-6 {
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            // padding-top: 10vh;
            display: grid;
            grid-template-rows: 10vh 1fr 10vh;
            grid-template-columns: 10vw 3fr 10vw;
            position: relative;
            overflow: hidden;

            .s4-title-container {
                width: 100vw;
                height: auto;
                padding: 0 7vw;
                position: relative;
                align-self: center;
                z-index: 100;

                .s4-title {
                    color: $blue;
                    font-family: $playfair;
                    font-size: 3.5em;
                    font-weight: 700;
                    cursor: default;      
                    background-color: white;
                    padding: 0 30px;
                    margin-left: 27vh;
                    z-index: 100;
                    position: relative;
                }

                .s4-title-hr {
                    width: 86vw;
                    position: absolute;
                    height: 1px;
                    background-color: black;
                    border: 0;
                    margin: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform-origin: left;
                    -webkit-transform-origin: left;
                    left: 7vw;
                    z-index: 1;
                }
            }

            .s4-content {
                grid-row: 2 / 3;  
                grid-column: 2 / 2;
                display: flex;
                color: $blue;
                font-family: $montserrat;
                font-size: .8em;
                font-weight: 400;
                // width: fit-content;
                height: fit-content;
                line-height: 35px;
                z-index: 100;
                justify-content: space-between;


                .content-s {
                    display: flex;
                    width: fit-content;
                    margin: 3vh 0;
                    vertical-align: baseline;
                    justify-content: center;
                    align-items: center;

                    .content-index {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: $blue;
                        margin-right: 15px;
                        align-self: center;
                        font-family: $playfair;
                        line-height: normal;
                        width: 5vh;
                    }
                }
            }

            .s4-circle {
                grid-row: 4 / 5;
                grid-column: 2 / 3;
                background: #f5f5f5;
                height: 70vh;
                width: 70vh;
                border-radius: 50%;
                position: absolute;
                top: -30vh;
                z-index: 1;
                right: -15vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
            
            .s4-square {
                grid-row: 4 / 5;
                grid-column: 3 / 4;
                background: #f5f5f5;
                height: 75vh;
                width: 35vh;
                position: absolute;
                top: -55vh;
                z-index: 1;
                left: 5vh;
                pointer-events: none;
                transform-origin: bottom;
                -webkit-transform-origin: bottom;
            }
        }
    }
}
