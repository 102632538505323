$blue: #1472B6;
$blue-hover: hsl(205, 80%, 25%);
$playfair: 'Playfair Display', serif;
$raleway: 'Raleway', sans-serif;
$montserrat: 'Montserrat', sans-serif;

.notification-card-container {
    height: 100vh;
    width: 100vw;
    background: rgba(51, 51, 51, .8);
    position: fixed;
    top: 0;
    margin: 0;
    padding: 0;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 10000000000;
    opacity: 0;
    
    .notification-card {
        height: 50%;
        width: 50%;
        background: white;
        position: relative;
        overflow: hidden;
        opacity: 0;
        transform: translateY(-30vh);
        -webkit-transform: translateY(-30vh);
        
        .notification-card-done {
            height: 5vh;
            width: 5vh;
            cursor: pointer;
            margin: 7vh 0 0 5vw;
        }

        .notification-card-text {
            display: block;
            color: black;
            z-index: 100;
            position: relative;
    
            &.text-1 {
                font-family: $playfair;
                font-size: 2em;
                font-weight: 600;
                margin: 15vh 0 0 5vw;
            }

            &.text-2 {
                font-family: $montserrat;
                font-size: 1.5em;
                font-weight: 400;
                margin: 3vh 0 0 5vw;
            }
        }

        .newsletter-img {
            height: 100%;
            width: 40%;
            position: absolute;
            right: 0;
            top: 0;
            justify-self: center;
            z-index: 1;
            background: $blue;
            margin: 0;
            padding: 0;
        }
    }
}