// $main-blue: #1472B6;
// $main-blue: rgb(46,67, 151);
$main-blue: rgb(46,67, 151);
$main-blue-hover: hsl(205, 80%, 25%);
$playfair: 'Playfair Display', serif;
$raleway: 'Raleway', sans-serif;
$montserrat: 'Montserrat', sans-serif;

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .header {
        width: 100vw;
        margin: 0;
        padding: 0;
        // padding-top: 24px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,.01);
        position: absolute;
        z-index: 100;
        // -webkit-box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.35); 
        // box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.35);
    
        .loading-curtain-1 {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: $main-blue;
            z-index: 99999999999;
            top: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform-origin: top;
            -webkit-transform-origin: top;
        }
    
        .loading-curtain-2 {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: #96C93C;
            z-index: 99999999999;
            top: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform-origin: top;
            -webkit-transform-origin: top;
        }
        
        .logo {
            height: calc(10vw + 35px);
            width: calc(10vw + 35px);
            margin: 0 0 0 10px;
            cursor: pointer;
        }
        
        .branding {
            font-family: $raleway;
            height: auto;
            width: auto;
            margin: 0 0 0 0.5vh;
            font-weight: 800;
            color: $main-blue;
            font-size: 1.2em;
            padding: 10px 0px;
        }
    
        .header-nav-container {
            height: fit-content;
            width: fit-content;
            margin: 0;
            padding: 0;
            margin: auto;
    
            .header-nav {
                display: inline-block;
                color: white;
                font-family: $montserrat;
                font-size: 1.2em;
                margin-right: 3vh;
                font-weight: 500;
                cursor: pointer;
                text-decoration: none;
            }
    
            .header-nav:after {
                display: block;
                content: '';
                border-bottom: 1px solid white;
                height: 2px;
                transform: scaleX(0);
                -webkit-transform: scaleX(0);
                transform-origin: left;
                -webkit-transform-origin: left;
                transition: .3s ease-in-out;
                margin: 0;
                padding: 0;
            }
    
            .header-nav:hover {
                &.header-nav:after {
                    transform: scaleX(1);
                    -webkit-transform: scaleX(1);
                    transition: .3s ease-in-out;
                }
            }
        }
    
        .switch-language-div {
            height: 4vh;
            width: fit-content;
            margin: 0 8vh 0 auto;
            z-index: 10;
            display: flex;
            align-items: center;
    
            .switch-language-btn {
                height: 4vh;
                width: 8vh;
                background: white;
                border: 1px solid black;
                color: black;
                font-family: $montserrat;
                font-size: 1.3em;
                padding: 0;
                cursor: pointer;
            }
        }

				.language-btn {
					padding: 10px !important;
				}
    
        .menu-white {
            height: 5vh;
            width: 5vh;
            margin: 0 6vh 0 auto;
            cursor: pointer;
            z-index: 1000;
            filter: invert(1);
            -webkit-filter: invert(1);
            display: none;
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .header {
        width: 100vw;
        margin: 0;
        padding: 0;
        // padding-top: 24px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(255,255,255,.01);
        position: absolute;
        z-index: 90;
        // -webkit-box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.35); 
        // box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.35);
    
        .loading-curtain-1 {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: $main-blue;
            z-index: 99999999999;
            top: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform-origin: top;
            -webkit-transform-origin: top;
        }
    
        .loading-curtain-2 {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: #96C93C;
            z-index: 99999999999;
            top: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform-origin: top;
            -webkit-transform-origin: top;
        }
        
        .logo {
            height: calc(7vh + 35px);
            width: calc(7vh + 35px);
            margin: 0 0 0 15px;
            cursor: pointer;
        }
        
        .branding {
            font-family: $raleway;
            height: auto;
            width: auto;
            margin: 0 0 0 2.5vh;
            font-weight: 800;
            color: $main-blue;
            font-size: 1.3em;
            padding: 10px 0px;
        }
    
        .header-nav-container {
            height: fit-content;
            width: fit-content;
            margin: 0;
            padding: 0;
            margin: auto;
    
            .header-nav {
                display: inline-block;
                color: white;
                font-family: $montserrat;
                font-size: 1.2em;
                margin-right: 3vh;
                font-weight: 700;
                cursor: pointer;
                text-decoration: none;
            }
    
            .header-nav:after {
                display: block;
                content: '';
                border-bottom: 1px solid white;
                height: 2px;
                transform: scaleX(0);
                -webkit-transform: scaleX(0);
                transform-origin: left;
                -webkit-transform-origin: left;
                transition: .3s ease-in-out;
                margin: 0;
                padding: 0;
            }
    
            .header-nav:hover {
                &.header-nav:after {
                    transform: scaleX(1);
                    -webkit-transform: scaleX(1);
                    transition: .3s ease-in-out;
                }
            }
        }

        .switch-language-div {
            height: 3vh;
            width: fit-content;
            // margin: 0 8vh 0 auto;
            z-index: 10;
            display: flex;
            align-items: center;
    
            .switch-language-btn {
                height: 3vh;
                width: 6vh;
                background: white;
                border: 1px solid black;
                color: black;
                font-family: $montserrat;
                font-size: 1em;
                padding: 0;
                cursor: pointer;
            }
        }
    
        .menu-white {
            height: 5vh;
            width: 5vh;
            margin: 0 6vh 0 auto;
            cursor: pointer;
            z-index: 1000;
            filter: invert(1);
            -webkit-filter: invert(1);
            display: none;
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .header {
        width: 100vw;
        margin: 0;
        padding: 0;
        // padding-top: 24px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(255,255,255,.01);
        position: absolute;
        z-index: 100;
        // -webkit-box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.35); 
        // box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.35);
    
        .loading-curtain-1 {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: $main-blue;
            z-index: 99999999999;
            top: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform-origin: top;
            -webkit-transform-origin: top;
        }
    
        .loading-curtain-2 {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: #96C93C;
            z-index: 99999999999;
            top: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform-origin: top;
            -webkit-transform-origin: top;
        }
        
        .logo {
            height: calc(9vh + 35px);
            width: calc(9vh + 35px);
            margin: 0 0 0 25px;
            cursor: pointer;
        }
        
        .branding {
            font-family: $raleway;
            height: auto;
            width: auto;
            margin: 0 0 0 2.5vh;
            font-weight: 800;
            color: $main-blue;
            font-size: 1.5em;
            padding: 10px 0px;
        }
    
        .header-nav-container {
            height: fit-content;
            width: fit-content;
            margin: 0;
            padding: 0;
            margin: auto;
    
            .header-nav {
                display: inline-block;
                color: white;
                font-family: $montserrat;
                font-size: 1.2em;
                margin-right: 3vh;
                font-weight: 700;
                cursor: pointer;
                text-decoration: none;
            }
    
            .header-nav:after {
                display: block;
                content: '';
                border-bottom: 1px solid white;
                height: 2px;
                transform: scaleX(0);
                -webkit-transform: scaleX(0);
                transform-origin: left;
                -webkit-transform-origin: left;
                transition: .3s ease-in-out;
                margin: 0;
                padding: 0;
            }
    
            .header-nav:hover {
                &.header-nav:after {
                    transform: scaleX(1);
                    -webkit-transform: scaleX(1);
                    transition: .3s ease-in-out;
                }
            }
        }
    
        .switch-language-div {
            height: 3vh;
            width: fit-content;
            // margin: 0 8vh 0 auto;
            z-index: 10;
            display: flex;
            align-items: center;
    
            .switch-language-btn {
                height: 3vh;
                width: 6vh;
                background: white;
                border: 1px solid black;
                color: black;
                font-family: $montserrat;
                font-size: 1em;
                padding: 0;
                cursor: pointer;
            }
        }
    
        .menu-white {
            height: 5vh;
            width: 5vh;
            margin: 0 6vh 0 auto;
            cursor: pointer;
            z-index: 1000;
            filter: invert(1);
            -webkit-filter: invert(1);
            display: none;
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .header {
        width: 100vw;
        margin: 0;
        padding: 0;
        // padding-top: 20px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,.01);
        position: absolute;
        z-index: 100;
        // -webkit-box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.35); 
        // box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.35);
    
        .loading-curtain-1 {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: $main-blue;
            z-index: 99999999999;
            top: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform-origin: top;
            -webkit-transform-origin: top;
        }
    
        .loading-curtain-2 {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: #96C93C;
            z-index: 99999999999;
            top: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform-origin: top;
            -webkit-transform-origin: top;
        }
        
        .logo {
            height: calc(9vw + 35px);
            width: calc(9vw + 35px);
            margin: 0 0 0 4vw;
            cursor: pointer;
        }
        
        .branding {
            font-family: $raleway;
            height: auto;
            width: auto;
            margin: 0 0 0 2.5vh;
            font-weight: 800;
            color: $main-blue;
            font-size: 1.5em;
            padding: 10px 0px;
        }
    
        .header-nav-container {
            height: fit-content;
            width: 80%;
            display: flex;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            margin: auto;
    
            .header-nav {
                display: inline-block;
                color: white;
                font-family: $montserrat;
                font-size: 1.2em;
                margin-right: 3vh;
                font-weight: 700;
                cursor: pointer;
                text-decoration: none;
            }
    
            .header-nav:after {
                display: block;
                content: '';
                border-bottom: 1px solid white;
                height: 2px;
                transform: scaleX(0);
                -webkit-transform: scaleX(0);
                transform-origin: left;
                -webkit-transform-origin: left;
                transition: .3s ease-in-out;
                margin: 0;
                padding: 0;
            }
    
            .header-nav:hover {
                &.header-nav:after {
                    transform: scaleX(1);
                    -webkit-transform: scaleX(1);
                    transition: .3s ease-in-out;
                }
            }
        }
    
        .switch-language-div {
            height: 4vh;
            width: fit-content;
            margin: 0 8vh 0 auto;
            z-index: 10;
            display: flex;
            align-items: center;
    
            .switch-language-btn {
                height: 4vh;
                width: 8vh;
                background: white;
                border: 1px solid black;
                color: black;
                font-family: $montserrat;
                font-size: 1.3em;
                padding: 0;
                cursor: pointer;
            }
        }
    
        .menu-white {
            height: 5vh;
            width: 5vh;
            margin: 0 6vh 0 auto;
            cursor: pointer;
            z-index: 1000;
            filter: invert(1);
            -webkit-filter: invert(1);
            display: none;
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .header {
        width: 100vw;
        margin: 0;
        padding: 0;
        // padding-top: 24px;
        font-size: 1.5em;
        height: fit-content;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,.01);
        position: absolute;
        z-index: 100;
        // -webkit-box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.35); 
        // box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.35);
    
        .loading-curtain-1 {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: $main-blue;
            z-index: 99999999999;
            top: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform-origin: top;
            -webkit-transform-origin: top;
        }
    
        .loading-curtain-2 {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: #96C93C;
            z-index: 99999999999;
            top: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform-origin: top;
            -webkit-transform-origin: top;
        }
        
        .logo {
            height: calc(6vw + 35px);
            width: calc(6vw + 35px);
            margin: 0 0 0 6vw;
            cursor: pointer;
        }
        
        .branding {
            font-family: $raleway;
            height: auto;
            width: auto;
            margin: 0 0 0 2.5vh;
            font-weight: 800;
            color: $main-blue;
            font-size: 1.4em;
            padding: 10px 0px;
        }
    
        .header-nav-container {
            height: fit-content;
            width: 65%;
            display: flex;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            margin: auto;
    
            .header-nav {
                display: inline-block;
                color: white;
                font-family: $montserrat;
                font-size: 1em;
                margin-right: 3vh;
                font-weight: 700;
                cursor: pointer;
                text-decoration: none;
            }
    
            .header-nav:after {
                display: block;
                content: '';
                border-bottom: 1px solid white;
                height: 2px;
                transform: scaleX(0);
                -webkit-transform: scaleX(0);
                transform-origin: left;
                -webkit-transform-origin: left;
                transition: .3s ease-in-out;
                margin: 0;
                padding: 0;
            }
    
            .header-nav:hover {
                &.header-nav:after {
                    transform: scaleX(1);
                    -webkit-transform: scaleX(1);
                    transition: .3s ease-in-out;
                }
            }
        }
    
        .switch-language-div {
            height: 3vh;
            width: fit-content;
            margin: 0 8vh 0 auto;
            z-index: 10;
            display: flex;
            align-items: center;
    
            .switch-language-btn {
                height: 3vh;
                width: 6vh;
                background: white;
                border: 1px solid black;
                color: black;
                font-family: $montserrat;
                font-size: .9em;
                padding: 0;
                cursor: pointer;
            }
        }
    
        .menu-white {
            height: 5vh;
            width: 5vh;
            margin: 0 6vh 0 auto;
            cursor: pointer;
            z-index: 1000;
            filter: invert(1);
            -webkit-filter: invert(1);
            display: none;
        }
    }
}

@media only screen and (min-width: 1920px) {
    .header {
        width: 100vw;
        margin: 0;
        padding: 0;
        // padding-top: 24px;
        font-size: 1.5em;
        height: fit-content;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,.01);
        position: absolute;
        z-index: 100;
        // -webkit-box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.35); 
        // box-shadow: -1px 5px 10px 5px rgba(0,0,0,0.35);
    
        .loading-curtain-1 {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: $main-blue;
            z-index: 99999999999;
            top: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform-origin: top;
            -webkit-transform-origin: top;
        }
    
        .loading-curtain-2 {
            height: 100vh;
            width: 100vw;
            position: fixed;
            background-color: #96C93C;
            z-index: 99999999999;
            top: 0;
            transform: scaleY(0);
            -webkit-transform: scaleY(0);
            transform-origin: top;
            -webkit-transform-origin: top;
        }
        
        .logo {
            height: calc(40px + 7vw);
            width: calc(40px + 7vw);
            margin: 0 0 0 8vh;
            cursor: pointer;
        }
        
        .branding {
            font-family: $raleway;
            height: auto;
            width: auto;
            margin: 0 0 0 2.5vh;
            font-weight: 800;
            color: $main-blue;
            font-size: 2em;
            padding: 10px 0px;
        }
    
        .header-nav-container {
            height: fit-content;
            width: 60%;
            display: flex;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            margin: auto;
    
            .header-nav {
                display: inline-block;
                color: white;
                font-family: $montserrat;
                font-size: 1.2em;
                margin-right: 3vh;
                font-weight: 700;
                cursor: pointer;
                text-decoration: none;
            }
    
            .header-nav:after {
                display: block;
                content: '';
                border-bottom: 1px solid white;
                height: 2px;
                transform: scaleX(0);
                -webkit-transform: scaleX(0);
                transform-origin: left;
                -webkit-transform-origin: left;
                transition: .3s ease-in-out;
                margin: 0;
                padding: 0;
            }
    
            .header-nav:hover {
                &.header-nav:after {
                    transform: scaleX(1);
                    -webkit-transform: scaleX(1);
                    transition: .3s ease-in-out;
                }
            }
        }
    
        .switch-language-div {
            height: 4vh;
            width: fit-content;
            margin: 0 8vh 0 auto;
            z-index: 1000;            
            display: flex;
            align-items: center;
    
            .switch-language-btn {
                height: 4vh;
                width: 8vh;
                background: white;
                border: 1px solid black;
                color: black;
                font-family: $montserrat;
                font-size: 1.3em;
                padding: 0;
                cursor: pointer;
            }
        }
    
        .menu-white {
            height: 5vh;
            width: 5vh;
            margin: 0 6vh 0 auto;
            cursor: pointer;
            z-index: 1000;
            filter: invert(1);
            -webkit-filter: invert(1);
            display: none;
        }
    }
}
